import React, { Component } from "react";
import { Auth } from "aws-amplify";

import {Container, Form, FormControl, FormGroup, FormLabel, Button, Row, Col, Alert} from 'react-bootstrap'
import './Auth.css'

import logo from './GuRu_Logomark.png';
import logo_name from './GuRu-logo-name.png';

export default class Forgot extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      msg: "",
      show_err: false,
      validated: false
    };
  }

  handleForgot = event => {
    const form = document.getElementById('signin_form');
    this.setState({validated: true});

    if (form.checkValidity() === false) {
      return;
    }

    event.target.innerHTML = '<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> ';
    this.setState({show_err: false});

    const { email } = this.props.inputs;
    console.log(email)
    // After retrieveing the confirmation code from the user
    Auth.forgotPassword(email)
      .then(() => this.props.switchComponent("ChangePass"))
      .catch(err => {
        document.getElementById('forgot').innerHTML = 'Forgot password';
        this.setState({show_err: true, msg: err.message});
      });
  };

  render() {
    return (
      <Container className='form'>
        <Row className="justify-content-md-center align-items-center">
          <Col lg='4'>
            <div>
              <img className="logo" src={logo} alt='Logo'></img>
              <img className="logo_name" src={logo_name} alt='LogoName'></img>
            </div>
            <Form id='signin_form' noValidate validated={this.state.validated} className='mt-5'>
              <FormGroup>
                <FormLabel>Email</FormLabel>
                <FormControl type="email" name="email" value={this.props.inputs.email} placeholder="Email" onChange={this.props.handleFormInput} required></FormControl>
                <Form.Control.Feedback type="invalid">
                  Please enter an email.
                </Form.Control.Feedback>
              </FormGroup>

              {this.state.show_err && <Alert variant='danger' onClose={() => this.setState({show_err: false})} dismissible>{this.state.msg}</Alert>}

              <Button id='forgot' className='btn-block grey_button' onClick={this.handleForgot}>Forgot password</Button>

              <Row>
                <Col>
                  <Button variant='link' className='btn-block btn-adds add-link' onClick={() => this.props.switchComponent("SignIn")}>Get back to SignIn</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        
      </Container>
    );
  }
}