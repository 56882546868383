import React, { Component } from 'react';
import {Container, Form, FormGroup, FormLabel, FormControl, Row, Col, Button, Card, Modal, Alert, Table, Badge} from 'react-bootstrap';

import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';

import './Calc.css';

class Calculator extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {
            gu_nx: 10,
            gu_ny: 10,
            ru_nx: 5,
            ru_ny: 5,
            ru_pos_x: 0,
            ru_pos_y: 0,
            ru_pos_z: 30,
            ru_norm_x: 0,
            ru_norm_y: 0,
            ru_norm_z: -1,
            ru_rot: 0,
            show_patient_notify: false,
            show_help: false,
            show_about: false,
            show_change: false,
            show_change_error: false,
            show_change_success: false,
            show_calc_error: false,
            validated: false,
            msg: ''
        };

        this.handleLogOut = this.handleLogOut.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.keyPressed = this.keyPressed.bind(this);
    }

    //HANDLERS
    keyPressed(e) {
        if (e.key === 'Escape') {
            alert("esc pressed")
        }
    }

    async handleLogOut(){
        await Auth.signOut()
            .then(()=>this.props.switchComponent("SignIn")) // switches to SignIn
            .catch(err => console.log(err));
    }

    async handleChangePass() {
        const form = document.getElementById('pass_form');
        if (form.checkValidity() === false) {
            return;
        }
    
        document.getElementById('change_btn').innerHTML = '<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> ';
        this.setState({show_err: false});
    
        const {old_password, new_password } = form;

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, old_password.value, new_password.value);
            })
            .then(() => {
                document.getElementById('change_btn').innerHTML = 'Change Password';
                this.setState({show_change_success:true, msg:'Success'})
            })
            .catch(err => {
                document.getElementById('change_btn').innerHTML = 'Change Password';
                this.setState({show_change_error: true, msg: err.message});
            })
    }

    handleCamChange = event => {
        var id = event.target.id;

        // eslint-disable-next-line default-case
        switch (id){
            case ('view_gu'):
                window.camera.position.x = 0;
                window.camera.position.y = 14 + Math.floor((Math.abs(window.ru.position.x) + Math.abs(window.ru.position.y) + Math.abs(window.ru.position.z)) / 3);
                window.camera.position.z = -18 - Math.floor((Math.abs(window.ru.position.x) + Math.abs(window.ru.position.y) + Math.abs(window.ru.position.z)) / 3);
                
                window.control.target = new THREE.Vector3(0, 0, 0);
                break;
            case ('view_ru'):
                window.camera.position.x = 0 + window.ru.position.x;
                window.camera.position.y = 8 + Math.floor(window.ru.position.y * 1.3);
                window.camera.position.z = 20 + Math.floor(window.ru.position.z * 0.9);

                window.control.target = new THREE.Vector3(0, 0, 0);
                break;
            case ('view_top'):
                window.camera.position.x = -1;
                window.camera.position.y = 35 + Math.floor((Math.abs(window.ru.position.x) + Math.abs(window.ru.position.z)) / 1.5);
                window.camera.position.z = Math.floor(window.ru.position.z / 2);

                window.control.target = new THREE.Vector3(0, 0, Math.floor(window.ru.position.z / 2));
                break;
            case ('view_side'):
                window.camera.position.x = -35 - Math.floor((Math.abs(window.ru.position.x) + Math.abs(window.ru.position.z)) / 1.5);
                window.camera.position.y = 0;
                window.camera.position.z = Math.floor(window.ru.position.z / 2);

                window.control.target = new THREE.Vector3(0, 0, Math.floor(window.ru.position.z / 2));
                break;
        }
            
    }

    handleOnChange(e){
        const elem_name = e.target.id;
        const value = e.target.value;

        this.setState({[elem_name]: parseFloat(e.target.value)}, this.scene_updater);

        //validation
        // eslint-disable-next-line default-case
        switch (elem_name) {
            case ("gu_nx"): {
                (value > 45 || value < 2.5) ? e.target.setCustomValidity("Incorrect") : e.target.setCustomValidity("");
                break;
            }
            case ("gu_ny"): {
                (value > 45 || value < 2.5) ? e.target.setCustomValidity("Incorrect") : e.target.setCustomValidity("");
                break;
            }
            case ("ru_nx"): {
                (value > 15 || value < 0.625) ? e.target.setCustomValidity("Incorrect") : e.target.setCustomValidity("");
                break;
            }
            case ("ru_ny"): {
                (value > 15 || value < 0.625) ? e.target.setCustomValidity("Incorrect") : e.target.setCustomValidity("");
                break;
            }
            case ("ru_pos_x"): {
                (value > 2000 || value < -2000) ? e.target.setCustomValidity("Incorrect") : e.target.setCustomValidity("");
                break;
            }
            case ("ru_pos_y"): {
                (value > 2000 || value < -2000) ? e.target.setCustomValidity("Incorrect") : e.target.setCustomValidity("");
                break;
            }
            case ("ru_pos_z"): {
                (value > 2000 || value < 1.2) ? e.target.setCustomValidity("Incorrect") : e.target.setCustomValidity("");
                break;
            }
        }
    }

    scene_updater() {
        // Gu set
        window.gu.scale.x = this.state.gu_nx;
        window.gu.scale.y = this.state.gu_ny;

        // Ru set
        window.ru.scale.x = this.state.ru_nx;
        window.ru.scale.y = this.state.ru_ny;

        window.ru.position.x = this.state.ru_pos_x;
        window.ru.position.y = this.state.ru_pos_y;
        window.ru.position.z = this.state.ru_pos_z;

        const {x, y} = this.get_xy_angles();
        window.ru.rotation.x = x * Math.PI / 180;
        window.ru.rotation.y = y * Math.PI / 180;
        window.ru.rotation.z = this.state.ru_rot * Math.PI / 180;
    }

    handleSubmit = event => {
        event.target.innerHTML = '<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> ';

        const form = document.getElementById('calc_form');
        this.setState({validated: false, show_calc_error: false});

        if (form.checkValidity() === false) {
            this.setState({validated:true, show_calc_error: true, msg: "Input values are incorrect."});
            document.getElementById('calculate').innerHTML = 'Calculate';
            return;
        }

        this.setState({show_patient_notify: true})
        
        Auth.currentCredentials()
            .then(credentials => {
                var AWS = require('aws-sdk');
                AWS.config.update({region:'us-east-2'});

                const lambda = new Lambda({
                    credentials: Auth.essentialCredentials(credentials),
                    maxRetries: 1,
                    httpOptions: {
                        timeout: 900000
                    }
                });

                Auth.currentAuthenticatedUser().then(user => {
                    return lambda.invoke({
                        FunctionName: "GuRu_calc", 
                        Payload: JSON.stringify({"body": JSON.stringify({
                                "gu_nx": this.state.gu_nx,
                                "gu_ny": this.state.gu_ny,
                                "ru_nx": this.state.ru_nx,
                                "ru_ny": this.state.ru_ny,
                                "ru_pos_x": this.state.ru_pos_x,
                                "ru_pos_y": this.state.ru_pos_y,
                                "ru_pos_z": this.state.ru_pos_z,
                                "ru_norm_x": this.state.ru_norm_x,
                                "ru_norm_y": this.state.ru_norm_y,
                                "ru_norm_z": this.state.ru_norm_z,
                                "ru_rot": this.state.ru_rot,
                                "user_id": user.username,
                                "user_email": user.attributes.email
                            })
                        })
                       }, (err, response) => {
                            if (err) {
                                document.getElementById('calculate').innerHTML = 'Calculate';
                                console.log(err)
                            }
                            else {
                                document.getElementById('calculate').innerHTML = 'Calculate';

                                var total_tx = document.getElementById("total_tx");
                                var rx_rf = document.getElementById("rx_rf");
                                var dc_ru = document.getElementById("dc_ru");
                                var air2air = document.getElementById("air2air");
    
                                var data = JSON.parse(response.Payload)
                                if (!data.body) return;

                                var body = JSON.parse(data.body)
                    
                                total_tx.innerHTML = Math.round(body.total_tx_power * 10000) / 10000;
                                rx_rf.innerHTML = Math.round(body.rx_rf_power * 10000) / 10000
                                dc_ru.innerHTML = Math.round(body.dc_ru_output * 10000) / 10000
                                air2air.innerHTML = Math.round(body.air2air_eff * 10000) / 10000    
                                
                                this.setState({show_patient_notify: false})
                            }
                       });
                })
                
            })
    }
    // END HANDLERS

    // HELPERS
    get_xy_angles(){
        const x = this.state.ru_norm_x;
        const y = this.state.ru_norm_y;
        const z = this.state.ru_norm_z;

        if (z >= 0){
            return {
                x: 180 / Math.PI * Math.atan2(-y, z),
                y: 180 / Math.PI * Math.atan2(x, z)
            }
        }
        else {
            return {
                x: 180 / Math.PI * Math.atan2(-y, z) + 180,
                y: 180 / Math.PI * Math.atan2(x, z)
            }
        }
    }
    // END HELPERS


    componentDidMount() {
        // add listener for keypress events
        document.addEventListener("keydown", this.keyPressed, false);

        // === THREE.JS CODE START ===

        // scene
        var scene = new THREE.Scene();

        // camera
        window.camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
        window.camera.position.x = -15.5;
        window.camera.position.y = 14.5;
        window.camera.position.z = -7.7;
        window.camera.lookAt(scene.position);

        // renderer
        var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        // 2 px for border
        renderer.setSize( this.mount.offsetWidth - 2, this.mount.offsetHeight - 2);
        window.camera.aspect = this.mount.offsetWidth/this.mount.offsetHeight;
        window.camera.updateProjectionMatrix();
        
        // use ref as a mount point of the Three.js scene instead of the document.body
        this.mount.appendChild( renderer.domElement );

        // resizing observer (it is here to update renderers size, if this needed)
        new ResizeObserver(() => {
            var elem = document.getElementById('model');

            if (!elem) return;

            var width = elem.offsetWidth;
            var height = elem.offsetHeight;
            renderer.setSize(width - 2, height - 2);
            window.camera.aspect = width/height;
            window.camera.updateProjectionMatrix();
        }).observe(this.mount);

        // eslint-disable-next-line no-unused-vars
        window.control = new OrbitControls(window.camera, renderer.domElement);

        // AXES

        // dom
        var container2 = document.getElementById('inset');

        // renderer
        var renderer2 = new THREE.WebGLRenderer({antialias: true, alpha: true});
        renderer2.setSize( 200, 200 );
        container2.appendChild( renderer2.domElement );
        // scene
        var scene2 = new THREE.Scene();

        // camera
        var camera2 = new THREE.PerspectiveCamera( 50, window.innerWidth/window.innerHeight, 1, 1000 );
        camera2.up = window.camera.up; // important!

        // axes
        var axes2 = new THREE.AxesHelper( 100 );
        scene2.add( axes2 );

        // END AXES

        
        var materials = [
            new THREE.MeshPhongMaterial({color: 0x768084, wireframe: false}),  // right
            new THREE.MeshPhongMaterial({color: 0x768084, wireframe: false}),  // left
            new THREE.MeshPhongMaterial({color: 0x768084, wireframe: false}),  // top
            new THREE.MeshPhongMaterial({color: 0x768084, wireframe: false}),  // bottom
            new THREE.MeshPhongMaterial({color: 0xAEBADF, wireframe: false}),  // front
            new THREE.MeshPhongMaterial({color: 0x768084, wireframe: false}),  // back
        ];

        // Create Gu geometry
        var guGeometry = new THREE.BoxGeometry( 1, 1, 1, .025 , 9 );
        window.gu = new THREE.Mesh(guGeometry, materials);
        window.gu.scale.set(this.state.gu_nx, this.state.gu_ny, 1.3);
        window.gu.receiveShadow = true;
        scene.add(window.gu);

        // Create Ru geometry
        var ruGeometry = new THREE.BoxGeometry( 1, 1, 1, .025 , 9 );
        window.ru = new THREE.Mesh(ruGeometry, materials);
        window.ru.scale.set(this.state.ru_nx, this.state.ru_ny, 0.9);
        window.ru.position.x = this.state.ru_pos_x;
        window.ru.position.y = this.state.ru_pos_y;
        window.ru.position.z = this.state.ru_pos_z;
        
        const {x, y} = this.get_xy_angles();
        window.ru.rotation.x = x * Math.PI / 180;
        window.ru.rotation.y = y * Math.PI / 180;
        window.ru.rotation.z = this.state.ru_rot * Math.PI / 180;

        window.ru.receiveShadow = true;
        scene.add(window.ru);

        // lights
        var ambientLight = new THREE.AmbientLight( 0xFFFFFF, 0.3);
        scene.add(ambientLight);

        var light = new THREE.DirectionalLight(0xFFFFFF, 0.9);
        light.position.set(-3, 15, 5);
        light.castShadow = true;
        light.shadow.camera.near = 0.1;
        light.shadow.camera.far = 25;
        light.target = window.gu;
        scene.add(light);

        var light2 = new THREE.DirectionalLight(0xFFFFFF, 0.8);
        light2.position.set(-3, 15, 5);
        light2.castShadow = true;
        light2.shadow.camera.near = 0.1;
        light2.shadow.camera.far = 25;
        light2.target = window.ru;
        scene.add(light2);

        var show = function () {
            requestAnimationFrame( show );

            window.control.update();
    
            camera2.position.copy( window.camera.position );
            camera2.position.sub( window.control.target ); // added by @libe
            camera2.position.setLength( 300 );

            camera2.lookAt( scene2.position );

            renderer.render(scene, window.camera);
            renderer2.render( scene2, camera2 );
        }

        show();

        // === THREE.JS EXAMPLE CODE END ===
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyPressed, false);
    }


    render() {
        return (
            <div className='wrap'>
                
                <nav className="navbar navbar-light fixed-top navbar-expand-lg static-top">
                    <div className="container">
                        <a className="navbar-brand" href="https://guru.inc/">
                        <img src="./img/GuRu-logo-name.png" alt="Guru" />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarResponsive"
                            aria-controls="navbarResponsive"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            >
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a href="https://guru.inc/technology/">TECHNOLOGY</a>
                            </li>
                            <li className="nav-item">
                                <a href="https://guru.inc/about-us/">ABOUT US</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="https://guru.inc/news/" id="navbarDropdownMenuLinkNews" aria-haspopup="true" aria-expanded="false">NEWS</a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkNews">
                                    <a className="dropdown-item" href="https://guru.inc/in-the-news/">IN THE NEWS</a>
                                    <a className="dropdown-item" href="https://guru.inc/press-releases/">PRESS RELEASES</a>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="https://guru.inc/contact/" id="navbarDropdownMenuLinkContact" aria-haspopup="true" aria-expanded="false">CONTACT</a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkContact">
                                    <a className="dropdown-item" href="https://guru.inc/media-resources/">MEDIA RESOURCES</a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Button variant='link' className='logout-link' onClick={this.handleLogOut}>LOGOUT</Button>
                            </li>
                        </ul>
                        </div>
                    </div>
                </nav>               
                            
                <Container className='main'>
                    <Row>
                        <Col>
                            <Button className='btn-block grey_button mb-3' variant="primary" onClick={() => this.setState({show_help: true})}>
                                Calculator Help
                            </Button>

                            <Modal size='lg' show={this.state.show_help} onHide={() => this.setState({show_help: false})} centered>
                                <Modal.Header closeButton>
                                <Modal.Title>Calculator Help</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='text-justify'>
                                    <p>GuRu’s wireless power technology is a scalable solution which can be designed to address specific applications requiring milliwatts to watts at desired distances. The delivered DC power depends on several parameters of the system such as the sizes of the generating unit (GU) and the recovery unit (RU), GU to RU distance, and orientation of the GU and RU with respect to each other. </p>
                                    <p>This calculator provides the customer a platform to estimate GU and RU sizes for their specific application and better understand the system trade-offs.</p>
                                    <h5 className="ml-5 mt-5"><u>Calculator Usage:</u></h5>

                                    <h6 className="ml-5">GU Length and Width [cm]  <Badge className='ml-5' variant='secondary'>Valid range: 2.5 - 45</Badge></h6>
                                    
                                    <p>The transmit power scales with the GU size. The air to air power transfer efficiency of the system, for a given distance and orientation between RU and GU, is also a function of GU size.</p>
                                
                                    <h6 className="ml-5">RU Length and Width [cm] <Badge className='ml-5' variant='secondary'>Valid range: 0.625 - 15</Badge></h6>
                                    <p>The maximum recoverable power scales with the RU size. The air to air efficiency is also affected by the RU size.</p>

                                    <h6 className="ml-5">GU Center Position and Orientation</h6>
                                    <p>The GU position is fixed. The center of the GU is the origin of the coordinate system. The GU orientation is fixed in the XY plane facing +Z direction. </p>

                                    <h6 className="ml-5">RU Center Position [cm] <Badge className='ml-5' variant='secondary'>Valid range: X, Y: -2000 - 2000; Z: 1.2 – 2000</Badge></h6>
                                    <p></p>

                                    <h6 className="ml-5">RU Normal Vector (Orientation)</h6>
                                    <p>X, Y, and Z are the RU normal vector components and specify the orientation of the RU. For example, if the Normal Vector is along -Z axis (Z&lt;0; X=0; Y=0), the RU will be facing the GU. If Z=0, the RU will be perpendicular to the GU.</p>

                                    <h6 className="ml-5">RU Rotation [deg]</h6>
                                    <p>Rotation (Yaw) specifies the in-plane rotation of RU. For example, if RU is facing the GU and Rotation=0, the length and width of RU are along the length and width of GU, respectively.</p>

                                    <h5 className="ml-5 mt-5"><u>Calculator Results:</u></h5>
                                    
                                    <p><span className="font-weight-bold">Total Tx Power:</span> The maximum available RF power from the GU transmitter. This power scales with size of the GU.</p>
                                    <p><span className="font-weight-bold">Rx RF Power:</span> The maximum available RF power that can be focused on the RU for current setting.</p>
                                    <p><span className="font-weight-bold">RU DC Output:</span> The maximum available DC power from RU after RF to DC conversion.</p>
                                    <p><span className="font-weight-bold">Air to Air Eff:</span> The air to air transfer efficiency of the system based on provided setting.</p>

                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({show_help: false})}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                        <Col>
                            <Button className='btn-block grey_button mb-3' variant="primary" onClick={() => this.setState({show_about: true})}>
                                About Calculator
                            </Button>

                            <Modal size='lg' show={this.state.show_about} onHide={() => this.setState({show_about: false})} centered>
                                <Modal.Header closeButton>
                                <Modal.Title>About Calculator</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='text-justify'>The calculator is a part of Guru’s proprietary technology. It is a convenient way for customers to experience GuRu’s wireless power transfer for their application. The calculator results are an estimate of what a customer can expect. GuRu regularly improves the calculator software based on customer feedback and usage. Therefore, the calculator results may vary over time and without notice.</Modal.Body>
                                <Modal.Footer className='text-center'>
                                    <div className="mx-auto">
                                        Copyright 2020, Guru Wireless, Inc., All rights reserved. <br /> Contact: support@guru.inc
                                    </div>                                    
                                </Modal.Footer>
                            </Modal>
                        </Col>
                        <Col>
                            <Button className='btn-block grey_button mb-3' variant="primary" onClick={() => this.setState({show_change: true})}>
                                Change Password
                            </Button>

                            <Modal show={this.state.show_change} onHide={() => this.setState({show_change: false})} centered>
                                <Modal.Header closeButton>
                                <Modal.Title>Change Password</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id='pass_form' noValidate validated={true}>
                                        <FormGroup>
                                            <FormLabel>Old Password</FormLabel>
                                            <FormControl type="password" minLength='8' name="old_password" placeholder="Old Password" onChange={this.props.handleFormInput} required></FormControl>
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your old password. Minimum 8 symbols.
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel>New Password</FormLabel>
                                            <FormControl type="password" minLength='8' name="new_password" placeholder="New Password" onChange={this.props.handleFormInput} required></FormControl>
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a new password. Minimum 8 symbols.
                                            </Form.Control.Feedback>
                                        </FormGroup>

                                        {this.state.show_change_error && <Alert variant='danger' onClose={() => this.setState({show_change_error: false})} dismissible>{this.state.msg}</Alert>}
                                        {this.state.show_change_success && <Alert variant='success' onClose={() => this.setState({show_change_success: false})} dismissible>{this.state.msg}</Alert>}
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>   
                                    <Button id='change_btn' variant="primary btn-block grey_button" onClick={this.handleChangePass}>
                                        Change Password
                                    </Button>
                                    <Button variant="light btn-block" onClick={() => this.setState({show_change: false, show_change_error: false,
                                                                                                    show_change_success: false})}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4}>
                            <Form id="calc_form" noValidate validated={this.state.validated}>
                                <fieldset>
                                    <legend>Power Generator Unit (GU) Size</legend>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <FormLabel>Length (cm)</FormLabel>
                                                <FormControl id='gu_nx' onChange={(e) => this.handleOnChange(e)} type="number" step='0.5' defaultValue={this.state.gu_nx} placeholder="GU Length (cm)" required
                                                ></FormControl>
                                                <Form.Control.Feedback type="invalid">
                                                    Input values should be in range from 2.5cm to 45cm.
                                                </Form.Control.Feedback>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <FormLabel>Width (cm)</FormLabel>
                                                <FormControl id='gu_ny' onChange={(e) => this.handleOnChange(e)} type="number" step='0.5' defaultValue={this.state.gu_ny} placeholder="GU Width (cm)"  required                                
                                                ></FormControl>
                                                <Form.Control.Feedback type="invalid">
                                                    Input values should be in range from 2.5cm to 45cm.
                                                </Form.Control.Feedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </fieldset>
                                <fieldset>
                                    <legend>Power Recovery Unit (RU) Size</legend>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <FormLabel>Length (cm)</FormLabel>
                                                <FormControl id='ru_nx' onChange={(e) => this.handleOnChange(e)} type="number" step='0.5' defaultValue={this.state.ru_nx} placeholder="RU Length (cm)" required
                                                ></FormControl>
                                                <Form.Control.Feedback type="invalid">
                                                    Input values should be in range from 0.625cm to 15cm.
                                                </Form.Control.Feedback>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <FormLabel>Width (cm)</FormLabel>
                                                <FormControl id='ru_ny' onChange={(e) => this.handleOnChange(e)} type="number" step='0.5' defaultValue={this.state.ru_ny} placeholder="RU Width (cm)" required
                                                ></FormControl>
                                                <Form.Control.Feedback type="invalid">
                                                    Input values should be in range from 0.625cm to 15cm.
                                                </Form.Control.Feedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </fieldset>
                                <Row>
                                    <Col>
                                        <fieldset>
                                            <legend>RU Position</legend>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <FormLabel>X (cm)</FormLabel>
                                                        <FormControl id='ru_pos_x' onChange={(e) => this.handleOnChange(e)} type="number" defaultValue={this.state.ru_pos_x} placeholder="RU Pos X (cm)" required
                                                        ></FormControl>
                                                        <Form.Control.Feedback type="invalid">
                                                            Input values should be in range from -2000 to 2000.
                                                        </Form.Control.Feedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <FormLabel>Y (cm)</FormLabel>
                                                        <FormControl id='ru_pos_y' onChange={(e) => this.handleOnChange(e)} type="number" defaultValue={this.state.ru_pos_y} placeholder="RU Pos Y (cm)" required
                                                        ></FormControl>
                                                        <Form.Control.Feedback type="invalid">
                                                            Input values should be in range from -2000 to 2000.
                                                        </Form.Control.Feedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <FormLabel>Z (cm)</FormLabel>
                                                        <FormControl id='ru_pos_z' onChange={(e) => this.handleOnChange(e)} type="number" defaultValue={this.state.ru_pos_z} placeholder="RU Pos Z (cm)" required
                                                        ></FormControl>
                                                        <Form.Control.Feedback type="invalid">
                                                            Input values should be in range from 1.2 to 2000.
                                                        </Form.Control.Feedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Col>
                                    <Col>
                                        <fieldset>
                                            <legend className="letter_sp_low">RU Normal Vector</legend>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <FormLabel>X</FormLabel>
                                                        <FormControl id='ru_norm_x' onChange={(e) => this.handleOnChange(e)} type="number" step='0.1' defaultValue={this.state.ru_norm_x} placeholder="RU Norm X" required></FormControl>
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </FormGroup>                                                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <FormLabel>Y</FormLabel>
                                                        <FormControl id='ru_norm_y' onChange={(e) => this.handleOnChange(e)} type="number" step='0.1' defaultValue={this.state.ru_norm_y} placeholder="RU Norm Y" required></FormControl>
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <FormLabel>Z</FormLabel>
                                                        <FormControl id='ru_norm_z' onChange={(e) => this.handleOnChange(e)} type="number" step='0.1' defaultValue={this.state.ru_norm_z} placeholder="RU Norm Z" required
                                                        onBlur={ e => {if (e.target.value > -0.01 && e.target.value < 0.01) {
                                                            if (e.target.value <= 0) {
                                                                this.setState({ru_norm_z: -0.01});
                                                                e.target.value = -0.01;
                                                            } 
                                                            else {
                                                                this.setState({ru_norm_z: 0.01});
                                                                e.target.value = 0.01;
                                                            }
                                                        }}}></FormControl>
                                                        <Form.Control.Feedback type="invalid">
                                                            This field is required.
                                                        </Form.Control.Feedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Col>
                                </Row>

                                <fieldset>
                                    <legend>RU Rotation (deg)</legend>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <FormControl id='ru_rot' onChange={(e) => this.handleOnChange(e)} type="number" defaultValue={this.state.ru_rot} placeholder="Rotation" required></FormControl>
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required.
                                                </Form.Control.Feedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </fieldset>
                                {this.state.show_calc_error && <Alert variant='danger' onClose={() => this.setState({show_calc_error: false})} dismissible>{this.state.msg}</Alert>}
                                <Button id='calculate' className="btn-block grey_button" onClick={this.handleSubmit}>Calculate</Button>
                                {this.state.show_patient_notify && <Alert className="mt-3" variant='secondary' onClose={() => this.setState({show_patient_notify: false})} dismissible>
                                    Calculation can take up to one minute. Please be patient.
                                </Alert>}
                            </Form>
                        </Col>

                        <Col lg={8}>
                            <Card className='mt-2'>
                                <div className="card-img-top" id='model' style={{height: '500px'}} ref={ref => (this.mount = ref)}>
                                    <div id='inset'></div>
                                </div>

                                <div className='card-body'>
                                    <div className='card-text'>
                                        <div className='sphere red'></div>
                                        <div className='sphere_label'> - X axis</div>
                                        <div className='sphere green'></div>                                        
                                        <div className='sphere_label'> - Y axis</div>
                                        <div className='sphere blue'></div>
                                        <div className='sphere_label'> - Z axis</div>
                                        
                                        <div className='sphere_label mr-0 float-right'> - Front side color</div>
                                        <div className='box front float-right'></div>
                                    </div>
                                </div>
                            </Card>

                            <Row className='mt-3'>
                                <Col>
                                    <Button id='view_gu' className="btn-block grey_button" onClick={this.handleCamChange}>View from GU</Button>
                                </Col>
                                <Col>
                                    <Button id='view_ru' className="btn-block grey_button" onClick={this.handleCamChange}>View from RU</Button>
                                </Col>
                                <Col>
                                    <Button id='view_top' className="btn-block grey_button" onClick={this.handleCamChange}>Top view</Button>
                                </Col>
                                <Col>
                                    <Button id='view_side' className="btn-block grey_button" onClick={this.handleCamChange}>Side view</Button>
                                </Col>
                            </Row>

                            <div className="results">
                                <fieldset>
                                    <legend>Calculator Results</legend>

                                    <Table hover className='text-center'>
                                        <tbody className="font-italic">
                                            <tr>
                                                <td style={{width: '50%'}}>Total Tx Power (W)</td>
                                                <td id="total_tx">No data yet..</td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>Rx RF Power (W)</td>
                                                <td id="rx_rf">No data yet...</td>
                                            </tr>
                                            <tr>
                                                <td style={{width: "50%"}}>DC RU Output (W)</td>
                                                <td id="dc_ru">No data yet...</td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '50%'}}>Air to Air Efficiency (%)</td>
                                                <td id="air2air">No data yet...</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </fieldset>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* <!-- Footer --> */}
                <footer id="footer" className="">
                    <div className="container">
                        <div className="icons">
                            <a
                                href="https://www.linkedin.com/company/guruwirelessinc/">
                                    <i className="fa fa-linkedin fa-lg"></i>
                            </a>
                            <a
                                href="https://twitter.com/GuRuWirelessInc">
                                    <i className="fa fa-twitter fa-lg"></i>
                            </a>
                            
                        </div>

                        <div className="copyright">
                            COPYRIGHT © 2020 GURU. ALL RIGHTS RESERVED.
                        </div>

                    </div>
                </footer>
                {/* <!-- End Footer --> */}
            </div>
        );
    }
}

export default Calculator;
