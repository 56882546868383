import React, { PureComponent } from "react";

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Verify from "./Verify";
import Forgot from "./Forgot";
import ChangePass from "./ChangePass";
import Calc from "../Calc";

import { Auth } from "aws-amplify";

export default class Authentication extends PureComponent {
  state = {
    username: "",
    email: "",
    password: "",
    phone_number: "",
    company: "",
    title: "",
    code: "",
    user: null, // will contain our user data object when signed in
    status: "SignIn"
  };

  UNSAFE_componentWillMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(data => {
        if (data.signInUserSession.idToken.payload['cognito:groups'] &&
            data.signInUserSession.idToken.payload['cognito:groups'].includes("Allowed_users")) {
              let user = {username:data.username,...data.attributes}
              this.setState({user, status:"Welcome"})
            }
      })
      .catch(err => console.log(err));
  }

  // Handle changes to form inputs on sign-up, verification and sign-in
  handleFormInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  AuthComponent = () => {
    switch (this.state.status) {
      case "SignUp":
        return (
          <SignUp
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );
        
      case "Verify":
        return (
          <Verify
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );
        
      case "SignIn":
        return (
          <SignIn
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );

      case "Forgot":
        return (
          <Forgot
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );

      case "ChangePass":
        return (
          <ChangePass
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );
        
      case "Welcome":
        return <Calc switchComponent={this.switchComponent} inputs={this.state}/>;
      default:
        return (
          <SignUp
            switchComponent={this.switchComponent}
            handleFormInput={this.handleFormInput}
            inputs={this.state}
          />
        );
    }
  };
  switchComponent = status => {
    this.setState({ status });
  };
  render() {
    return <div>{this.AuthComponent()}</div>;
  }
}