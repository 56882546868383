/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d134ca35-5275-47ba-b32b-c9f92f905a18",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_7RoLJVYYz",
    "aws_user_pools_web_client_id": "uq500spknoja9oofj58420mds",
    "oauth": {},
    "Auth": {
        "region": "us-east-2",
        "userPoolId": "us-east-2_7RoLJVYYz",
        "userPoolWebClientId": "uq500spknoja9oofj58420mds"
    }
};


export default awsmobile;
