import React, { Component } from "react";
import { Auth } from "aws-amplify";

import {Container, Form, FormControl, FormGroup, FormLabel, Button, Row, Col, Alert} from 'react-bootstrap'
import './Auth.css'

import logo from './GuRu_Logomark.png';
import logo_name from './GuRu-logo-name.png';


export default class SignUp extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      msg: "",
      show_err: false,
      validated: false
    };
}

  handleSignUp = (event) => {
    const form = document.getElementById('signup_form');
    this.setState({validated: true});

    if (form.checkValidity() === false) {
      return;
    }

    event.target.innerHTML = '<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> ';
    this.setState({show_err: false});

    const {email, password, company, title, name } = this.props.inputs;
    Auth.signUp({
      username: email,
      password,
      attributes: {
        name: name,
        email, 
        'custom:Company': company,
        'custom:Title': title
      }
    })
      .then(()=>this.props.switchComponent("Verify")) // switches Sign Up to Verification
      .catch(err => {
        document.getElementById('signUp').innerHTML = 'SignUp';
        this.setState({show_err: true, msg: err.message});
      })
  };

  render() {
    return (
      <Container className='form'>
        <Row className="justify-content-md-center align-items-center">
          <Col lg='4'>
            <div>
              <img className="logo" src={logo} alt='Logo'></img>
              <img className="logo_name" src={logo_name} alt='LogoName'></img>
            </div>
            <Form id='signup_form' className='mt-5' noValidate validated={this.state.validated}>
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl autoComplete='off' type="text" name="name" placeholder="Name" onChange={this.props.handleFormInput} defaultValue='' required></FormControl>
                <Form.Control.Feedback type="invalid">
                  Please enter a name.
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>Email</FormLabel>
                <FormControl autoComplete='off' type="email" name="email" placeholder="Email" onChange={this.props.handleFormInput} defaultValue='' required></FormControl>
                <Form.Control.Feedback type="invalid">
                  Please enter an email.
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>Password</FormLabel>
                <FormControl type="password" minLength='8' name="password" placeholder="Password" onChange={this.props.handleFormInput} defaultValue='' required></FormControl>
                <Form.Control.Feedback type="invalid">
                  Please enter a password. Minimum 8 symbols.
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>Company</FormLabel>
                <FormControl type="text" name="company" placeholder="Company" onChange={this.props.handleFormInput} defaultValue='' required></FormControl>
                <Form.Control.Feedback type="invalid">
                  Please enter a company.
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <FormLabel>Title</FormLabel>
                <FormControl type="text" name="title" placeholder="Title" onChange={this.props.handleFormInput} defaultValue=''></FormControl>
              </FormGroup>

              {this.state.show_err && <Alert variant='danger' onClose={() => this.setState({show_err: false})} dismissible>{this.state.msg}</Alert>}

              <Row className='mt-4'>
                <Col lg='4'>
                  <Button id='signUp' className='btn-block grey_button' onClick={this.handleSignUp}>SignUp</Button>
                </Col>
                <Col>
                  <Button variant='link' className='btn-block btn-adds' onClick={() => this.props.switchComponent("SignIn")}>Already have account? Sign in</Button>
                </Col>
              </Row> 
              <Row>
                <Col>
                  <Button variant='link' className='btn-block btn-adds add-link' onClick={() => this.props.switchComponent("Verify")}>Provide email verification</Button>
                </Col>
              </Row>  
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}