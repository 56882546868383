import React from 'react';

import Authentication from './auth/Authentication'

function App() {
  return (
    <div>
      <Authentication />
    </div>
  );
}

export default App;
