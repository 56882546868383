import React, { Component } from "react";
import { Auth } from "aws-amplify";

import {Container, Form, FormControl, FormGroup, FormLabel, Button, Row, Col, Alert} from 'react-bootstrap'
import './Auth.css'

import logo from './GuRu_Logomark.png';
import logo_name from './GuRu-logo-name.png';


export default class SignIn extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      msg: "",
      show_err: false
    };
}

  handleSignIn = event => {
    event.target.innerHTML = '<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> ';
    this.setState({show_err: false});
    const { email, password } = this.props.inputs;

    // You can pass an object which has the username, password and validationData which is sent to a PreAuthentication Lambda trigger
    Auth.signIn({ username: email, password })
      .then((data) => {
        if (data.signInUserSession.idToken.payload['cognito:groups'] &&
            data.signInUserSession.idToken.payload['cognito:groups'].includes("Allowed_users")) {
              this.props.switchComponent("Welcome")
            }
        else {
          throw Error("GuRu Admins have not verified your account yet.")
        }
      })
      .catch(err => {
        document.getElementById('signIn').innerHTML = 'SignIn';
        this.setState({show_err: true, msg: err.message});
      });
  };

  render() {
    return (
      <Container className='form'>
        <Row className="justify-content-md-center align-items-center">
          <Col lg='4'>
            <div>
              <img className="logo" src={logo} alt='Logo'></img>
              <img className="logo_name" src={logo_name} alt='LogoName'></img>
            </div>
            <Form className='mt-5'>
              <FormGroup>
                <FormLabel>Email</FormLabel>
                <FormControl type="email" name="email" value={this.props.username} placeholder="Email" onChange={this.props.handleFormInput}></FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Password</FormLabel>
                <FormControl type="password" name="password" value={this.props.password} placeholder="Password" onChange={this.props.handleFormInput}></FormControl>
              </FormGroup>

              {this.state.show_err && <Alert variant='danger' onClose={() => this.setState({show_err: false})} dismissible>{this.state.msg}</Alert>}
              
              <Row className='mt-4'>
                <Col lg='4'>
                  <Button id='signIn' className='btn-block grey_button' onClick={this.handleSignIn}>SignIn</Button>
                </Col>
                <Col>
                  <Button variant='link' className='btn-block btn-adds' onClick={() => this.props.switchComponent("SignUp")}>No account? Request Access</Button>
                </Col>
              </Row>     

              <Row>
                <Col>
                  <Button variant='link' className='btn-block btn-adds add-link' onClick={() => this.props.switchComponent("Forgot")}>Forgot password?</Button>
                </Col>
              </Row>        
              
            </Form>
          </Col>
        </Row>
        
      </Container>
    );
  }
}