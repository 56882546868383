import React, { Component } from "react";
import { Auth } from "aws-amplify";

import {Container, Form, FormControl, FormGroup, FormLabel, Button, Row, Col, Alert} from 'react-bootstrap'
import './Auth.css'

import logo from './GuRu_Logomark.png';
import logo_name from './GuRu-logo-name.png';

export default class Verify extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      show_success: false,
      show_msg: false,
      validated: false,
      show_err: false,
      msg: ""
    };
  }

  handleVerification = event => {
    const form = document.getElementById('verify_form');
    this.setState({validated: true});

    if (form.checkValidity() === false) {
      return;
    }

    event.target.innerHTML = '<i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> ';

    const { email, code } = this.props.inputs;
    // After retrieveing the confirmation code from the user
    Auth.confirmSignUp(email, code)
      .then(() => this.setState({show_success: true}))
      .catch(err => {
        document.getElementById('verify').innerHTML = 'Verify Email';
        this.setState({show_err: true, msg:err.message})
      });
  };

  handleResendSignUp = event => {
    const { email } = this.props.inputs
    Auth.resendSignUp(email)
    .then(() => this.setState({show_msg: true}))
    .catch(err => this.setState({show_err: true, msg:err.message}))
  }

  render() {
    return (
      <Container className='form'>
        <Row className="justify-content-md-center align-items-center">
          <Col lg='4'>
            <div>
              <img className="logo" src={logo} alt='Logo'></img>
              <img className="logo_name" src={logo_name} alt='LogoName'></img>
            </div>

            {this.state.show_err && <Alert variant='danger' onClose={() => this.setState({show_err: false})} dismissible>{this.state.msg}</Alert>}
            
            {this.state.show_success && 
                <Alert variant='success'>GuRu will review your request and you will receive confirmation after access is granted.</Alert>
            }

            {!this.state.show_success && 
              <Form id='verify_form' noValidate validated={this.state.validated} className='mt-5'>
                {this.state.show_msg && <Alert variant='success' onClose={() => this.setState({show_msg: false})} dismissible>Confirmation code resent successfully.</Alert>}
                <FormGroup>
                  <FormLabel>Email</FormLabel>
                  <FormControl autoComplete='off' type="email" name="email" placeholder="Email" onChange={this.props.handleFormInput} defaultValue={this.props.inputs.email} required></FormControl>
                  <Form.Control.Feedback type="invalid">
                    Please enter an email.
                  </Form.Control.Feedback>
                  <Button variant='link' className='btn-adds' onClick={this.handleResendSignUp}>ReSend Email</Button>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Code</FormLabel>
                  <FormControl autoComplete='off' type="text" minLength='6' maxLength='6' name="code" placeholder="Code" onChange={this.props.handleFormInput} defaultValue='' required></FormControl>
                  <Form.Control.Feedback type="invalid">
                    Please enter a 6-digit code. 
                  </Form.Control.Feedback>
                </FormGroup>    
                <Button id='verify' className='btn-block grey_button' onClick={this.handleVerification}>Verify Email</Button>  
              </Form>        
            }

            <Row>
              <Col>
                <Button variant='link' className='btn-block btn-adds add-link' onClick={() => this.props.switchComponent("SignIn")}>Get back to SignIn</Button>
              </Col>
            </Row>         
          </Col>
        </Row>
        
      </Container>
    );
  }
}